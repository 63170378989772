export const salehoursTwo = {
  primary: false,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  icon: true,
  topLine: ' Tailored business solutions',
  // headline: 'Comprehensive suite of integrated business solutions tailored for B2B sales and operations management.',
  headline: '',
  headlineTwo: '',
  headlineThree: '',
  headlineOne: '',
  descriptionOne:
    'Optimize costs, boost revenue, and streamline your business operations on our fully integrated platform. Leverage Salehours to manage sales, operations, and drive innovation.',
  buttonLabel: 'Download App',
  imgStart: '',
  imgSalehours: require('../../images/salehoursOne/salehours.png'),
  img: require('../../images/2.png'),
  alt: 'Salehours',
  start: 'start'
};

export const salehoursThree = {
  primary: false,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  icon: true,
  topLine: ' Tailored business solutions',
  // headline: 'Comprehensive suite of integrated business solutions tailored for B2B sales and operations management.',
  headline: '',
  headlineTwo: 'Salehours',
  headlineThree: 'Dashboard',
  headlineOne: 'Empower Your Business with ',
  descriptionOne:
    'Gain real-time insights, manage sales, and streamline operations effortlessly with our intuitive and fully integrated dashboard.',
  buttonLabel: 'Download App',
  imgStart: '',
  imgSalehours: require('../../images/salehoursOne/salehours.png'),
  img: require('../../images/salehours/salehours_dashboard.png'),
  alt: 'Salehours',
  start: 'start'
};


export const salehoursFour = {
  primary: false,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  icon: true,
  topLine: ' Tailored business solutions',
  // headline: 'Comprehensive suite of integrated business solutions tailored for B2B sales and operations management.',
  headline: '',
  headlineOne: 'Transform Your Business with ',
  headlineTwo: 'Salehours',
  descriptionOne:
    'Experience seamless management on our fully integrated platform. Perfectly optimized for mobile, Salehours empowers you to handle sales, streamline operations, and drive innovation on the go.',
  buttonLabel: 'Download App',
  imgStart: '',
  imgSalehours: require('../../images/salehoursOne/salehours.png'),
  img: require('../../images/salehours/salehours_mobile.png'),
  alt: 'Salehours',
  start: 'start'
};


export const salehoursOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Business Operations',
  headline: 'Business Suite',
  descriptionOne: "Optimize inventory with ensuring real-time tracking over stock levels",
  descriptionTwo: "Fast and unlimited invoicing capabilities to boost efficiency",
  descriptionThree: "Effortlessly manage all your contacts and streamline communications",
  descriptionFour: "Optimize growth with a single streamline system",
  imgStart: '',
  imgone: require('../../images/salehoursOne/1.png'),
  imgtwo: require('../../images/salehoursOne/2.png'),
  imgthree: require('../../images/salehoursOne/3.png'),
  imgfour: require('../../images/salehoursOne/4.png'),
  imgsalehours: require('../../images/salehoursOne/salehours.png'),
  alt: 'Salehours',
  start: ''
};
