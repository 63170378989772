import React from 'react';
import { Pricing } from '../../components';
import { Helmet } from 'react-helmet-async';

function SalehoursPricing() {
  return (
    <>
      <Helmet>
        <title>Contact Sales | Sabzland</title>
        <meta name="description" content="Start Free or Find a plan that’s right for you." />
        <link rel="canonical" href="https://www.sabzland.com/pricing" />
      </Helmet>
      <Pricing />
    </>
  );
}

export default SalehoursPricing;