import React from 'react';
import { Contact } from '../../components';
import { Helmet } from 'react-helmet-async';

function Contacts() {
  return (
    <>
      <Helmet>
        <title>Contact Sales | Sabzland</title>
        <meta name="description" content="Connect with Salehours Sales Team. Whether you have questions about our product or need a demo. Tell us about your business and we’ll get you to the right place." />
        <link rel="canonical" href="https://www.sabzland.com/contact" />
      </Helmet>
      <Contact />
    </>
  );
}

export default Contacts;