import styled from 'styled-components';

export const InfoSec = styled.div`
    color: #fff;
    padding: 160px 0;
    background: ${({lightBg}) => (lightBg ? '#fff' : '#09160F')};
    @media (min-width: 320px) and (max-width: 768px) {
      padding: 60px 0;
    }      
`;

export const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const InfoColumn = styled.div`
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    // justify-content: center;
  
  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ContentColumn = styled.div`
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  
  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImgColumn = styled.div`
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  
  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ContentWrap = styled.div`
  
  padding-top: 60px;
  padding-bottom: 40px;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 65px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;  
  }
`;

export const Heading = styled.h1`
  max-width: 1000px;
  text-align: center;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 600;
  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;  
  }
`;

export const Paragraph = styled.p`
  max-width: 440px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
  margin-bottom: 16px;
`;

export const CardWrap = styled.div({
  height: '535px',
  width: '70%',
  display: 'flex',
  justifyContent: 'center',

  '@media (min-width: 480px) and (max-width: 1200px)': {
    height: '535px',
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
  },
  
  '@media (min-width: 320px) and (max-width: 480px)': {
    height: '535px',
    display: 'flex',
    justifyContent: 'center',
  },
});

// export const CardWrap = styled.div`
//   display: flex;
//   justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
// `;