  export const businessSuiteOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Business Operations',
    headline: 'Business Suite',
    descriptionOne: "Dodi is a leading fresh and frozen fish provider in the United Kingdom. They provide an extensive range of products across the United Kingdom and Europe.",
    descriptionTwo: "Transformative! This Sabzland Suite has revolutionized our operations, enabling us to manage and scale our fresh produce business like never before.",
    buttonLabel: 'Learn More',
    imgStart: '',
    imgone: require('../../images/icons/comma.png'),
    imgtwo: require('../../images/doodi.png'),
    testimonialimgOne: require('../../images/ceodoodifoods.png'),
    testimonialName: "Zaheer Ahmed",
    testimonialDesignation: "Chief Executive Ofiicer",
    testimonialCompany: "Doodi Seafood",
    alt: 'Testimonial',
    start: ''
  };

  export const sustainableOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'All-in-one Suite',
    headline: 'Empowering businesses worldwide',
    description: "With a presence spanning across multiple countries, we drive efficiency, growth, and innovation for our clients.", 
    textOne: "100+ Business",
    textTwo: "Worldwide",
    textThree: "4+ Countries",
    textFour: "Adding more every month",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/sustainable.png'),
    alt: 'sabzland sustainable',
    start: ''
  };

  export const empoweringBusinesses = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Global Growth',
    headline: 'Empowering businesses worldwide',
    description: "With a presence spanning across multiple countries, we drive efficiency, growth, and innovation for our clients.", 
    textOne: "5+",
    textTwo: "Countries are part of Sabzland",
    textThree: "100+",
    textFour: "Businesses have joined Sabzland",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/globe.png'),
    alt: 'sabzland sustainable',
    start: ''
  };

  export const agricultureOne = {
    primary: true,
    lightBg: false,
    lightTopLine: false,
    lightText: true,
    lightTextDesc: true,
    icon: true,
    topLine: 'Empowering Agriculture',
    headline: 'Building Infrastructure for Businesses',
    descriptionOne:
      'Sabzland is transforming the agriculture sector by empowering farmers and agricultural businesses. We offer a unique combination of features, all in one place.',
    buttonLabel: 'Download App',
    imgStart: '',
    img: require('../../images/1.png'),
    bgImage: require('../../images/3.png'),
    alt: 'Connectpur',
    start: ''
  };

  export const salehoursFlow = {
    primary: false,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    icon: true,
    topLine: ' Tailored business solutions',
    // headline: 'Comprehensive suite of integrated business solutions tailored for B2B sales and operations management.',
    headline: 'Comprehensive suite for B2B sales and operations management',
    descriptionOne:
      'Optimize costs, boost revenue, and streamline your business operations on our fully integrated platform. Leverage Salehours to manage sales, operations, and drive innovation.',
    buttonLabel: 'Download App',
    imgStart: '',
    img: require('../../images/2.png'),
    alt: 'Salehours',
    start: ''
  };