import React from 'react'
import {InfoSec, InfoRow, HeadingTwo, TextWrapper, TopLine, Heading, Subtitle, UnorderedList, ListItem} from './Privacy.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

 const Privacy = ({
    lightBg,
    topLine,
    lightTopLine
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                
                                <Heading>Privacy Policy for Sabzland</Heading>
                                <Subtitle>Sabzland, Technologies Pvt limited. and its affiliates and subsidiaries (referred to collectively as "Sabzland") respect your privacy. We want you to be aware of how we collect, use, and handle your information across all our websites and applications that reference this Policy.</Subtitle>
                                
                                <Heading>Introduction</Heading>
                                <Subtitle>Your privacy is important to us. This Privacy Policy outlines how we collect, use, protect, and disclose personal information when you use our website, Sabzland.com ("Website"), our software platform ("Salehours"), and other services (collectively, "Services"). We aim to ensure that your data is handled responsibly and transparently.</Subtitle> 
                                <Subtitle>By using our Website or any of our Services, you consent to this Privacy Policy. Please read it carefully.</Subtitle>
                                
                                <Heading>1. Information We Collect</Heading>
                                <HeadingTwo>Personal Information</HeadingTwo>
                                <UnorderedList>
                                    <Subtitle>We collect personal identification information when you voluntarily provide it during registration, such as:</Subtitle>
                                    <ListItem>Name, company name, address, phone number, and email address.</ListItem>
                                    <ListItem>Billing information, including payment details.</ListItem>
                                    <ListItem>Device information (IP address, browser data, etc.).</ListItem>
                                    <ListItem>Employment details, social media profiles, and other data you may share.</ListItem>
                                </UnorderedList>
                                
                                <HeadingTwo>Non-Personal Information</HeadingTwo>
                                <UnorderedList>
                                    <Subtitle>We also collect non-personal data automatically, such as:</Subtitle>
                                    <ListItem>Browsing patterns, usage behavior, clickstream data.</ListItem>
                                    <ListItem>Analytics data, aggregated user trends.</ListItem>
                                </UnorderedList>

                                <Heading>2. How We Collect Your Information</Heading>
                                <UnorderedList>
                                    <Subtitle>We collect your information when you:</Subtitle>
                                    <ListItem>Register for our Services.</ListItem>
                                    <ListItem>Contact us for customer support.</ListItem>
                                    <ListItem>Interact with our Website or Services.</ListItem>
                                    <ListItem>Use third-party services integrated with our platform (e.g., analytics tools).</ListItem>
                                </UnorderedList>
                                
                                <Heading>3. Use of Your Information</Heading>
                                <UnorderedList>
                                    <Subtitle>We use the collected information for the following purposes:</Subtitle>
                                    <ListItem>To provide, maintain, and improve our Services.</ListItem>
                                    <ListItem>To personalize your experience with our platform.</ListItem>
                                    <ListItem>To process payments and manage your account.</ListItem>
                                    <ListItem>To communicate with you, including updates, support messages, and marketing (as permitted).</ListItem>
                                    <ListItem>To enhance security and prevent fraud.</ListItem>
                                </UnorderedList>

                                <Heading>4. Sharing Your Information</Heading>
                                <UnorderedList>
                                    <Subtitle>We will not sell your information to third parties. However, we may share your information:</Subtitle>
                                    <ListItem>With service providers who assist us in delivering our Services.</ListItem>
                                    <ListItem>If required by law or to protect our rights, property, and safety, or that of others.</ListItem>
                                    <ListItem>In the case of a merger or acquisition, where your data may be transferred to the new entity.</ListItem>
                                </UnorderedList>
                                
                                <Heading>5. Cookies and Tracking Technologies</Heading>
                                <Subtitle>Sabzland uses cookies and similar technologies to enhance your user experience. These may track your preferences and provide us with analytical data to improve our Services. You can adjust your browser settings to refuse cookies, but this may affect your experience on oursite.</Subtitle>
                                
                                <Heading>6. Data Security</Heading>
                                <Subtitle>We implement industry-standard security measures to protect your personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</Subtitle>

                                <Heading>7. Your Privacy Rights</Heading>
                                <Subtitle>Depending on your location, you may have certain rights regarding your personal data, such as:</Subtitle>
                                <UnorderedList>
                                    <ListItem>The right to access or correct your data.</ListItem>
                                    <ListItem>The right to request the deletion of your data.</ListItem>
                                    <ListItem>The right to object to or restrict certain types of processing.</ListItem>
                                </UnorderedList>
                                <Subtitle>For users in the European Economic Area (EEA) or California (CCPA), additional protections may apply.</Subtitle>

                                
                                <Heading>8. Changes to This Policy</Heading>
                                <Subtitle>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and where appropriate, notified to you.</Subtitle>

                                
                                <Heading>9. Contact Us</Heading>
                                <Subtitle>If you have any questions about this Privacy Policy, please contact us at info@sabzland.com.</Subtitle>

                            </TextWrapper>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default Privacy;