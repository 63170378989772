import React, {useState, useEffect} from 'react'
import { Nav, 
    NavbarContainer, 
    NavLogo, 
    StyledButton, 
    HamburgerIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavItemBtn,
    NavBtnLink,
    Img, NavSpace
 } from './Navbar.elements'
import { FaTimes, FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib'

function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const [homeClick, setHomeClick] = useState(false);
    const [contactClick, setContactClick] = useState(false);
    const [productsClick, setProductsClick] = useState(false);
    const [pricingClick, setPricingClick] = useState(false);

    const handleHomeClick = () => {
        setHomeClick(true);
        setProductsClick(false);
        setContactClick(false);
        setPricingClick(false);
    }
    const handleContactClick = () => {
        setHomeClick(false);
        setProductsClick(false);
        setContactClick(true);
        setPricingClick(false);
    }
    const handleProductsClick = () => {
        setHomeClick(false);
        setProductsClick(true);
        setContactClick(false);
        setPricingClick(false);
    }
    const handlePricingClick = () => {
        setHomeClick(false);
        setProductsClick(false);
        setContactClick(false);
        setPricingClick(true);
    }

    const handleClick = () =>  setClick(!click);
    
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        // so if the screensize is <= 960px then set button state to false
        if(window.innerwidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();
    }, [])

    window.addEventListener('resize', showButton);

    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/'> 
                        <Img src={require('../../images/sabzland_logo.png')} alt="Sabzland"  />
                    </NavLogo>
                    <HamburgerIcon onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </HamburgerIcon>
                    <NavMenu onClick={handleClick} click={click} >
                        <NavItem onClick={handleHomeClick} homeClick={homeClick}>
                            <NavLinks to='/' onClick={closeMobileMenu}>
                                Home
                            </NavLinks>
                        </NavItem>
                    
                     
                        <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                            <NavLinks to='/salehours' onClick={closeMobileMenu}>
                                Salehours
                            </NavLinks>
                        </NavItem>
                        
                        <NavItem onClick={handlePricingClick} pricingClick={pricingClick}>
                            <NavLinks to='/pricing' onClick={closeMobileMenu}>
                                Pricing
                            </NavLinks>
                        </NavItem>
                        <NavSpace/>
                        <NavItem onClick={handleContactClick} contactClick={contactClick}>
                            <NavLinks to='/contact' onClick={closeMobileMenu}>
                                Contact Sales
                            </NavLinks>
                        </NavItem>
                    


                        <NavItemBtn >
                            {button ? (
                                <NavBtnLink href='https://app.salehours.com/' target='_blank' rel='noopener noreferrer'>
                                    <StyledButton primary>Sign In
                                        {/* &nbsp; <PiArrowRightBold className="button-icon" size={20} /> */}
                                    </StyledButton> 
                                </NavBtnLink>
                            ) : (
                                <NavBtnLink href='https://app.salehours.com/' target='_blank' rel='noopener noreferrer'>
                                    {/* <Button onClick={closeMobileMenu} fontBig primary>SIGN UP</Button> */}
                                    <StyledButton onClick={closeMobileMenu} fontBig primary>Sign In
                                        {/* &nbsp; <PiArrowRightBold className="button-icon" size={20} /> */}
                                    </StyledButton>
                                </NavBtnLink>
                            )}
                            
                        </NavItemBtn>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>    
        </>
    )
}

export default Navbar
