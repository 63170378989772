import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GiCancel, GiShakingHands } from 'react-icons/gi';
import { FaUsers } from 'react-icons/fa';

export const StyledIcon1 = styled(GiShakingHands)`
  color: #5ce1e6;
  width: 50px;
  height: 50px;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

export const StyledIcon2 = styled(GiCancel)`
  color: #5ce1e6;
  width: 50px;
  height: 50px;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

export const StyledIcon3 = styled(FaUsers)`
  color: #5ce1e6;
  width: 50px;
  height: 50px;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

export const PricingSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #09160F;
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
  color: #fff;
  font-size: 48px;
  margin-bottom: 24px;
  text-align: center;
  @media screen and (max-width: 960px) {
    font-size: 36px;
  }  
`;

export const FreeHeading = styled.h1`
  color: #5ce1e6;
  font-size: 48px;
  margin-bottom: 24px;
  text-align: center;
  @media screen and (max-width: 960px) {
    font-size: 36px;
  }  
`;

export const FeatureText = styled.h1`
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  margin-left: 10px;
  text-align: center;
  @media screen and (max-width: 960px) {
    font-size: 18px;
  } 
`;

export const PricingSubtitle = styled.p`
  color: #fff;
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PricingCard = styled.a`
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 550px;
  text-decoration: none;
  border-radius: 30px;
  margin: 0 16px;
  padding-top: 20px;
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    margin: 10px;
    &:hover {
      transform: none;
    }
  }
`;

export const EnterpriseCard = styled(Link)`
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 550px;
  text-decoration: none;
  border-radius: 30px;
  margin: 0 16px;
  padding-top: 20px;
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 650px;
  padding: 24px;
  // align-items: center;
  color: #000;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 12px;
  font-size: 24px;
  color: #000000;
`;

export const PricingCardCost = styled.h4`
  font-size: 24px;
`;

export const PerMonthText = styled.h4`
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
`;

export const PricingCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 12px;
`;

export const PricingCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  // align-items: center;
  color: #a9b3c1;
`;

export const PricingCardFeature = styled.li`
  font-size: 13px;
  margin-bottom: 5px;
  color: #000000;

  // text-align: center;
`;

export const StyledButton = styled.button`
  background-color: #5ce3d9;
  color: #000;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: #4cc2b7;
  }
`;

export const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5ce1e6;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  p {
    color: #fff;
    font-size: 14px;
  }
`;

export const FeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-direction: row;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StartFree = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;


export const PerMonth = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

export const FeatureUP = styled.div`
  width: 100%;
  margin-bottom: 24px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`;