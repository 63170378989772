import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom' 
import GlobalStyles from './globalStyles'
import { Navbar, Footer } from './components';
import Home from './pages/HomePage/Home';
import Products from './pages/Products/Products';
import SalehoursPricing from './pages/SalehoursPricing/SalehoursPricing';
import Contacts from './pages/Contacts/Contacts';
import PrivacyPage from './pages/Privacy/Privacy';
import TermsPage from './components/ConsentContent/Terms';
import GDPRPage from './components/ConsentContent/GDPR';
import ScrollToTop from './components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <GlobalStyles />
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/salehours' element={<Products/>} />
          <Route path='/pricing' element={<SalehoursPricing/>} />
          <Route path='/contact' element={<Contacts/>} />
          <Route path='/privacypolicy' element={<PrivacyPage/>} />
          <Route path='/terms' element={<TermsPage/>} />
          <Route path='/complianceguide' element={<GDPRPage/>} />
        </Routes>
        <Footer />
      </Router>
  </HelmetProvider>
  );
}

export default App;
