import React from 'react'
import {InfoSec, InfoRow, HeadingTwo, TextWrapper, TopLine, Heading, Subtitle, UnorderedList, ListItem} from './Privacy.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

 const Terms = ({
    lightBg,
    topLine,
    lightTopLine
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                
                                <Heading>GDPR Compliance Guide for Sabzland</Heading>
                                <Subtitle>The General Data Protection Regulation (GDPR) is a legal framework established by the European Union to standardize data regulation across Europe and give consumers greater control over their personal data. It is an enhanced version of the previous Data Protection Directive.</Subtitle> 
                                <Subtitle>The primary objective of the GDPR is to protect the privacy rights of EU citizens, particularly their "right to be forgotten"—the right to request organizations to identify and remove any personal data they hold.</Subtitle>
                                <Subtitle>This guide outlines how Sabzland is preparing for GDPR compliance and offers an overview of the new requirements to help your business ensure readiness for GDPR.</Subtitle>

                                <Heading>Sabzland’s Commitment to Protecting Personal Data</Heading>
                                <Subtitle>As a data processor accountable for safeguarding customer data, Sabzland has updated its platform to ensure procedures are in place to protect Personal Data from any unauthorized access, theft, loss, leakage, or sharing. We will obtain your explicit consent before collecting any personal information</Subtitle>
                                
                                <Heading>What Should You Do to Prepare?</Heading>
                                <Subtitle>If your business operates within the European Union (EU) or processes the personal data of EU citizens, GDPR directly impacts you.</Subtitle>
                                <Subtitle>The GDPR mandates that you obtain freely given, specific, informed, and unambiguous consent from individuals before collecting their personal data. You must clearly state how you intend to use their data.</Subtitle>
                                <Subtitle>For businesses serving customers within the EU, it is your responsibility to seek their consent for storing personal details and obtain their permission before sending follow-up marketing or promotional communications through Sabzland’s platform. Based on customer preferences, you can decide the extent of details you wish to store for each individual. We recommend consulting legal counsel to guide your GDPR preparations.</Subtitle>

                                <Heading>Sabzland's GDPR-Compliant Features</Heading>
                                <Subtitle>We have made several updates to ensure compliance with GDPR, effective from March 25, 2018. These updates are designed to assist you in meeting GDPR requirements and include:</Subtitle>
                                <UnorderedList>
                                    <ListItem>Customer Registration Process: For EU-based customers, consent is required for data processing during registration.</ListItem>
                                    <ListItem>Data Recording for Sabzland Users: Businesses using Sabzland must obtain consent from customers before recording their data. For example, if a recycling business collects data, they must first ask the customer for permission to store their details.</ListItem>
                                    <ListItem>Edit or Forget Rights for End Customers: Customers can request their information be updated or permanently deleted from Sabzland’s system.</ListItem>
                                </UnorderedList>
                                <Subtitle>The following sections of our platform have been updated to reflect GDPR requirements:</Subtitle>
                                <UnorderedList>
                                    <ListItem>Customer Management</ListItem>
                                    <ListItem>Sales and Invoicing</ListItem>
                                    <ListItem>Inventory Management</ListItem>
                                    <ListItem>Public API</ListItem>
                                    <ListItem>Booking Widgets</ListItem>
                                    <ListItem>Customizable Data Processing Widgets</ListItem>
                                </UnorderedList>
                                <Subtitle>Upon customer consent to be forgotten, Sabzland will remove the individual’s records and adjust relevant business statistics reports to reflect anonymized data, ensuring that overall analytics remain accurate.</Subtitle>

                                <Heading>Customer Rights</Heading>
                                <Subtitle>Under GDPR, Sabzland users (like your business) are considered “Data Controllers.” If you serve customers within the EU, and their data is recorded in Sabzland, you must uphold the following rights:</Subtitle>
                                <UnorderedList>
                                    <ListItem>Right to be Informed: Individuals have the right to know how their data is being processed.</ListItem>
                                    <ListItem>Right to Rectification: Individuals can request corrections to their data if it is inaccurate or incomplete.</ListItem>
                                    <ListItem>Right to be Forgotten: Individuals can request the deletion of their personal data from Sabzland’s systems.</ListItem>
                                    <ListItem>Right to Object: Individuals can object to the use of their data for profiling or direct marketing.</ListItem>
                                    <ListItem>Right to Restrict Processing: Individuals may request that their data be stored but not processed.</ListItem>
                                    <ListItem>Right to Data Portability: Individuals have the right to request a copy of their personal data in a readable format.</ListItem>
                                </UnorderedList>
                                
                                <Subtitle>Sabzland will continue updating and refining our GDPR compliance features as part of our commitment to protecting your customers’ data while keeping your business compliant.</Subtitle>

                            </TextWrapper>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default Terms;