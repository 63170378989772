import React from 'react';
import { InfoSectionHome, SalehoursFlow, BusinessSuite, Sustainable, BuildingBusiness } from '../../components';
// import Cards from '../../components/Cards/Cards';
import { businessSuiteOne, empoweringBusinesses, agricultureOne, salehoursFlow } from './Data';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Sabzland</title>
                <meta name="description" content="Building Infrastructure for Businesses" />
                <link rel="canonical" href="https://www.sabzland.com/pricing" />
            </Helmet>
            <BuildingBusiness {...agricultureOne} />
            <InfoSectionHome {...salehoursFlow} />
            <SalehoursFlow />
            <Sustainable {...empoweringBusinesses} />
            <BusinessSuite {...businessSuiteOne} />
        </>
    )
}

export default Home;