import React from 'react';

import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
  FeatureItem,
  StyledButton,
  FeatureContainer,
  FeatureUP,
  FeatureText,
  StartFree,
  FreeHeading,
  PerMonth,
  PerMonthText,
  EnterpriseCard,
  StyledIcon1,
  StyledIcon2,
  StyledIcon3
} from './Pricing.elements';

const IconStyle = {
  color: '#5ce1e6',
  width: '50px',
  height: '50px',
};

const Pricing = () => {
  return (
    <IconContext.Provider value={{ color: '#5ce1e6' }}>
      <PricingSection>
        <PricingWrapper>
          <FeatureUP>
            <PricingHeading>Find a plan that’s right for you.</PricingHeading>
            <StartFree>
              <PricingHeading>Start&nbsp;</PricingHeading>
              <FreeHeading>Free.</FreeHeading>
            </StartFree>
            <FeatureContainer>
              <FeatureItem><StyledIcon1 /><FeatureText>Unlimited Support</FeatureText></FeatureItem>
              <FeatureItem><StyledIcon2 /><FeatureText>Cancel Anytime</FeatureText></FeatureItem>
              <FeatureItem><StyledIcon3 /><FeatureText>Free Setup Guide</FeatureText></FeatureItem>
            </FeatureContainer>
          </FeatureUP>
          <PricingContainer>
            <PricingCard href='https://app.salehours.com/' target='_blank' rel='noopener noreferrer'>
              <PricingCardInfo>
                <PricingCardPlan>Basic</PricingCardPlan>
                <PerMonth>
                  <PricingCardCost>$50&nbsp;</PricingCardCost>
                  <PerMonthText>/month</PerMonthText>
                </PerMonth>
                <PricingCardLength>Save $20 Paid Annually</PricingCardLength>
                <StyledButton primary>Try for Free</StyledButton>
                <PricingCardFeatures>
                  <PricingCardFeature>Sales Tracking</PricingCardFeature>
                  <PricingCardFeature>Track Inventory</PricingCardFeature>
                  <PricingCardFeature>Inventory Alerts</PricingCardFeature>
                  <PricingCardFeature>Business Accounting</PricingCardFeature>
                  <PricingCardFeature>Bills and Invoice Accounting</PricingCardFeature>
                  <PricingCardFeature>Multiple Currencies</PricingCardFeature>
                  <PricingCardFeature>Invoice Generation (Max 1000)</PricingCardFeature>
                  <PricingCardFeature>Manage Users (Up to 2)</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard href='https://app.salehours.com/' target='_blank' rel='noopener noreferrer'>
              <PricingCardInfo>
                <PricingCardPlan>Standard</PricingCardPlan>
                <PerMonth>
                  <PricingCardCost>$70&nbsp;</PricingCardCost>
                  <PerMonthText>/month</PerMonthText>
                </PerMonth>
                <PricingCardLength>Save $20 Paid Annually</PricingCardLength>
                <StyledButton primary>Try for Free</StyledButton>
                <PricingCardFeatures>
                  <PricingCardFeature>Sales Tracking</PricingCardFeature>
                  <PricingCardFeature>Track Inventory</PricingCardFeature>
                  <PricingCardFeature>Inventory Alerts</PricingCardFeature>
                  <PricingCardFeature>Business Accounting</PricingCardFeature>
                  <PricingCardFeature>Bills and Invoice Accounting</PricingCardFeature>
                  <PricingCardFeature>Multiple Currencies</PricingCardFeature>
                  <PricingCardFeature>Invoice Generation (Unlimited)</PricingCardFeature>
                  <PricingCardFeature>Manage Users (Up to 15)</PricingCardFeature>
                  <PricingCardFeature>Sabzland AI Assistant for Sales</PricingCardFeature>
                  <PricingCardFeature>Manage Business Profitability</PricingCardFeature>
                  <PricingCardFeature>Custom Role Permission</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
            </PricingCard>
            <EnterpriseCard to='/contact'  target='_blank' rel='noopener noreferrer'>
              <PricingCardInfo>
                <PricingCardPlan>Enterprise</PricingCardPlan>
                <StyledButton primary>Contact Sales</StyledButton>
                <PricingCardFeatures>
                  <PricingCardFeature>Let us help you define your business requirements and create a package that perfectly aligns with your goals.</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
            </EnterpriseCard>            
            {/* <PricingCard to='/contact'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCutDiamond />
                </PricingCardIcon>
                <PricingCardPlan>Enterprise</PricingCardPlan>
                <PricingCardCost>Contact Sales</PricingCardCost>
                <PricingCardLength></PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Let us help you define your business requirements and create a package that perfectly aligns with your goals.</PricingCardFeature>
                </PricingCardFeatures>
                <StyledButton primary>Contact Sales</StyledButton>
              </PricingCardInfo>
            </PricingCard> */}
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}

export default Pricing;
