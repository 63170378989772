import { useState} from 'react';
import {InfoSec, InfoRow, InfoColumn, ContactRow, ContactColumn, ErrPosition, TextWrapper, Heading, ContactHeading, TextWrapper2,StartFree,
    Formtitle, ErrMessage, Subtitle, ButtonPosition, InputField, TextCenter, ColorHeading, TopCenter} from './Contact.elements';
import { Container, Button } from '../../globalStyles';
import { PiNavigationArrowFill } from "react-icons/pi";

 const Contact = ({ 
    lightBg,
    lightText,
    lightTextDesc,
    imgStart,

}) => {

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");
  
    const submitForm = async () => {
  
        if (firstname === "" || lastname === "" || phonenumber === "" || email === "" || message === "") {
            setStatus("Fields are missing!")
            return
        }
    
        if (!email.includes('@')){
            setStatus("Invalid email")
            return
        }
        // url
        const URL = "https://api.sabzland.com/contact/Contactform";
    
        const config = {
            method: 'POST',
            body: JSON.stringify ({
            firstname,
            lastname,
            email,
            phonenumber,
            message
            }),
            headers: { 'Content-Type' : 'application/json'}
        }
        
        const response = await fetch(URL, config)
        const data = await response.text()
        //console.log(response.text())
        setStatus(data)
    }

    return (
        <>
            <InfoSec lightBg={lightBg}>
                {/* <Container>

                    <InfoRow imgStart={imgStart}>
                        
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText}>Address</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>Technology Park, Pak Austria Fachhochschule IAST, Haripur</Subtitle>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText}>Contact</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>Mobile: (+92) 3255419051</Subtitle>
                                <Subtitle lightTextDesc={lightTextDesc}>Mail: contact@sabzland.com</Subtitle>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText}>Supporting Hours</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>Monday-Friday: 9:00 AM - 6:00 PM</Subtitle>
                                <Subtitle lightTextDesc={lightTextDesc}>Saturday-Sunday: 9:00 AM - 2:00 PM</Subtitle>
                                </TextWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container> */}
                <Container>
                    <TopCenter>
                        <TextWrapper>
                            <ContactHeading>Connect with our</ContactHeading>
                            <StartFree>
                                <ColorHeading>Sales&nbsp;</ColorHeading>
                                <ContactHeading>Team</ContactHeading>
                                <ColorHeading>.</ColorHeading>
                            </StartFree>
                        </TextWrapper>
                    </TopCenter>
                    <TextCenter>
                        <TextWrapper2>
                            <Formtitle>Whether you have questions about our product or need a demo. </Formtitle>
                        </TextWrapper2>
                    </TextCenter>
                    <TextCenter>
                        <TextWrapper>
                            <Subtitle>Tell us about your business and we’ll get you to the right place.</Subtitle>
                        </TextWrapper>
                    </TextCenter>

                    <ContactRow>
                        <ContactColumn>
                            <InputField id="firstname" type="text" placeholder='Full Name *' onChange={(event) => setFirstname(event.target.value)}/>
                        </ContactColumn>

                        <ContactColumn>
                            <InputField id="email" type="email" placeholder='Email *' onChange={(event) => setEmail(event.target.value)}/>
                        </ContactColumn>

                        <ContactColumn>
                            <InputField id="phonenumber" type="tel" placeholder='Phone Number *' onChange={(event) => setPhonenumber(event.target.value)}/>
                        </ContactColumn>
                    </ContactRow>
                    
                    <ContactRow>
                        <ContactColumn>
                            <InputField id="lastname" type="text" placeholder='Company *' onChange={(event) => setLastname(event.target.value)}/>
                        </ContactColumn>

                        <ContactColumn>
                            <InputField id="message" type="text" placeholder='Country *' onChange={(event) => setMessage(event.target.value)}/>
                        </ContactColumn>
                    </ContactRow>
                    
                    <ContactRow>
                        <ErrPosition>
                            <ErrMessage>{status}</ErrMessage>
                        </ErrPosition>
                    </ContactRow>

                    <ContactRow>
                        <ButtonPosition>
                            <Button learnmore={true} onClick={submitForm}>
                                <PiNavigationArrowFill className="button-icon" size={18} />
                                Submit
                            </Button>
                        </ButtonPosition>
                    </ContactRow>

                </Container>
               
                                
            </InfoSec>
        </>
    )
}

export default Contact;
