import React from 'react'
import {InfoSec, InfoRow, HeadingTwo, TextWrapper, TopLine, Heading, Subtitle, UnorderedList, ListItem} from './Privacy.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

 const Terms = ({
    lightBg,
    topLine,
    lightTopLine
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                
                                <Heading>Terms & Conditions - Sabzland</Heading>
                                
                                <Heading>Welcome to Sabzland!</Heading>
                                <Subtitle>Thank you for choosing to use Sabzland's platform. We are dedicated to providing you with operational management services through our software (the "Software") that will allow you to efficiently manage inventory, sales, and accounting for your business. These Terms & Conditions ("Terms") govern your use of the services provided on our platform, accessible through our website and applications (collectively the "Service"), and form an agreement between you and Sabzland Technologies Pvt Limited. ("Sabzland," "Provider," "we," or "us"). By using the Website, App, or Service, you agree to comply with and be bound by these Terms. The term "you" refers to the user or viewer of our Website, App, and/or Service</Subtitle> 
                                
                                <Heading>1. Acceptance and Variation of Terms</Heading>
                                <Subtitle>By using our Service, you agree to these Terms. We reserve the right to change these Terms at any time without notice. Your continued use of the Service after changes are posted means you accept those changes. If you do not agree to these Terms, you must discontinue using the Service immediately.</Subtitle>

                                <Heading>2. Account Termination</Heading>
                                <Subtitle>Sabzland reserves the right to terminate your account at any time, with or without notice, particularly if false or inaccurate account information is provided.</Subtitle>
                                
                                <Heading>3. Payment Information</Heading>
                                <Subtitle>You agree to pay in advance for the services you receive. Failure to provide payment may result in the suspension of your account.</Subtitle>

                                <Heading>4. Indemnification</Heading>
                                <Subtitle>You agree to indemnify and hold Sabzland harmless from any claims arising from your use of the Service or violation of these Terms</Subtitle>
                                
                                <Heading>3. Payment Information</Heading>
                                <Subtitle>You agree to pay in advance for the services you receive. Failure to provide payment may result in the suspension of your account.</Subtitle>

                                <Heading>5. Warranties</Heading>
                                <Subtitle>By accessing or using the Service, you represent and warrant that you are of legal age in your jurisdiction and that you have authority to enter into these Terms on behalf of any organization you represent. You also agree to use the Service in compliance with all applicable laws and regulations.</Subtitle>
                                
                                <Heading>6. Disclaimer</Heading>
                                <Subtitle>The Service is provided "as is." Sabzland makes no warranties, express or implied, regarding the Service. We do not guarantee that the Service will be error-free or uninterrupted.</Subtitle>
                                
                                <Heading>7. Third-Party Services</Heading>
                                <Subtitle>Sabzland may provide links to third-party services. You acknowledge that these services are governed by their own terms and Sabzland is not responsible for their performance or content.</Subtitle>

                                <Heading>8. Payment and Refund Policy</Heading>
                                <Subtitle>You agree to pay the fees associated with your subscription level. Subscriptions may be canceled, but no refunds will be provided for the remainder of the billing period unless specified otherwise. If you pay annually, you may request a full refund within 14 days of payment</Subtitle>
                                
                                <Heading>9. Service Availability</Heading>
                                <Subtitle>Sabzland will make reasonable efforts to ensure the Service is available 24/7 but may be subject to downtimes for maintenance or other issues beyond our control.</Subtitle>

                                
                                <Heading>10. Intellectual Property</Heading>
                                <Subtitle>Sabzland owns all intellectual property rights related to the Service. You are granted a limited license to use the Service for your business operations but may not copy, modify, or distribute any materials provided by Sabzland without our consent.</Subtitle>

                                
                                <Heading>11. Limitation of Liability</Heading>
                                <Subtitle>Sabzland's liability is limited to resupplying the Service or refunding the amount paid by you, at our discretion. We are not liable for any indirect or consequential damages, including loss of business or profits.</Subtitle>
             
                                <Heading>12. Governing Law</Heading>
                                <Subtitle>These Terms are governed by the laws of the state of Pakistan. Any disputes will be resolved in the courts located in Pakistan.</Subtitle>
             
                                <Heading>13. Changes to the Service</Heading>
                                <Subtitle>Sabzland reserves the right to modify the Service or its content at any time without notice.</Subtitle>
             
                                <Heading>14. Contact Us</Heading>
                                <Subtitle>If you have any questions, you can reach us at info@sabzland.com</Subtitle>

                            </TextWrapper>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default Terms;