import React, { useState } from 'react';
import { ContentWrap, CardWrap, Heading, Img,  InfoSec, InfoColumn, InfoRow, } from './styles';
import { Container } from '../../globalStyles';

const cards = [
  { color: '#2d6a4f', content: '“Connetpur”', contentOne: 'Elevate Your Sales Game', contentTwo: '', subcontent: '', image: require('../../images/salehours_flow/1.png') },
  { color: '#40916c', content: '', contentOne: 'Seamless sales collaboration with suppliers and customers', contentTwo: 'Innovative app that integrates market trends, a collaborative community platform, and a digital marketplace, tailored for the agriculture needs.', subcontent: 'Connetpur', image: require('../../images/salehours_flow/2.png') },
  { color: '#52b788', content: 'Efficiency Unleashed, Seamlessly.', contentOne: 'Solution for business accounting and precise tax calculations', contentTwo: '', subcontent: 'Sabzland', image: require('../../images/salehours_flow/3.png') },
  { color: '#74c69d', content: 'Data-Driven Growth, Rooted in Efficiency.', contentOne: 'Stay on top of your stock with Salehours', contentTwo: '', subcontent: 'Sabzland Business Suite', image: require('../../images/salehours_flow/4.png') },
  { color: '#95d5b2', content: 'Navigating Complexity, Simplifying Solutions.', contentOne: 'Simplify your business finances with Salehours', contentTwo: '', subcontent: 'Sabzland Business Suite', image: require('../../images/salehours_flow/5.png') },
  { color: '#d8f3dc', content: 'Navigating Complexity, Simplifying Solutions.', contentOne: 'Streamline your billing process with Salehours', contentTwo: '', subcontent: 'Sabzland Business Suite', image: require('../../images/salehours_flow/6.png') },
];

const paragraphs = [
  'An agriculture platform for real-time market insights, knowledge exchange, and a marketplace for seamless trade.',
  '',
  '',
  '',
  '',
];

const Cards = () => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const index = Math.round((scrollTop / (scrollHeight - clientHeight)) * (cards.length - 1));
    setCurrentCardIndex(index);
  };

  return (
    <>
      <InfoSec>
        <Container>
          <InfoRow>
            <InfoColumn>
              <ContentWrap>
                {/* <GParagraph>{cards[currentCardIndex].subcontent}</GParagraph> */}
                <Heading>{cards[currentCardIndex].contentOne}</Heading>
                {/* <HeadingOne>{cards[currentCardIndex].content}</Heading>
                <HeadingTwo>{cards[currentCardIndex].contentTwo}</HeadingTwo>
                <Paragraph>{paragraphs[currentCardIndex]}</Paragraph> */}
              </ContentWrap>
            </InfoColumn>
            <InfoColumn>
              <CardWrap onScroll={handleScroll} style={{ overflowY: 'scroll', maxHeight: '500px' }}>
                {cards.map((card, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: '#09160F',
                      borderRadius: '3% / 5%',
                      marginBottom: '50px',
                      padding: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Img src={card.image} alt={card.content} style={{ width: 'auto', height: 'auto' }} />
                  </div>
                ))}
              </CardWrap>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
};

export default Cards;
