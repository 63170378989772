import React from 'react';
import { Salehours, InfoSection } from '../../components';
import { salehoursOne, salehoursTwo, salehoursThree, salehoursFour } from './Data';
import { Helmet } from 'react-helmet-async';

function Products() {
  return (
    <>
      <Helmet>
        <title>Salehours | Sabzland</title>
        <meta name="description" content="Optimize costs, boost revenue, and streamline your business operations on our fully integrated platform. Leverage Salehours to manage sales, operations, and drive innovation." />
        <link rel="canonical" href="https://www.sabzland.com/salehours" />
      </Helmet>    
      <InfoSection {...salehoursTwo} />
      <Salehours {...salehoursOne}/>
      <InfoSection {...salehoursThree} />
      <InfoSection {...salehoursFour} />
    </>
  );
}

export default Products;