import React from 'react'
import {InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading,  Subtitle, ImgWrapper, Img, StyledButton, ToSalehours} from './InfoSectionHome.elements'
import { Container } from '../../globalStyles'
// import { IoLogoGooglePlaystore } from "react-icons/io5";
import { PiArrowRightBold } from "react-icons/pi";

const InfoSection = ({
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    descriptionOne,
    descriptionTwo,
    img,
    alt,
    imgStart,
    start,
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                            <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                            
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle lightTextDesc={lightTextDesc}>{descriptionOne}</Subtitle>
                            <Subtitle lightTextDesc={lightTextDesc}>{descriptionTwo}</Subtitle>
                            {/* <Link style={{ textDecoration: 'none' }} to={linked}>
                            {button && <Button learnmore={primary}>
                                {icon && <IoLogoGooglePlaystore icon={icon} className="button-icon" size={18} />}
                                {buttonLabel}
                            </Button>}  */}
                            <ToSalehours href='https://salehours.com/' target='_blank' rel='noopener noreferrer'>
                                <StyledButton primary>Start now &nbsp;
                                    <PiArrowRightBold className="button-icon" size={20} />
                                </StyledButton>                            
                            </ToSalehours>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                        <ImgWrapper start={start}>
                            <Img src={img} alt={alt} />
                        </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoSection;