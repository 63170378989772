import styled from 'styled-components';


export const InfoSec = styled.div`
    color: #fff;
    padding: 80px 0;
    background: ${({lightBg}) => (lightBg ? '#fff' : '#09160F')};
    @media (min-width: 320px) and (max-width: 768px) {
      padding: 60px 0;
    }      
`

export const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
  @media (min-width: 480px) and (max-width: 1200px) {
    flex-direction: column-reverse;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  flex-basis: 50%;
  
  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;


export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 65px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;  
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;

export const TopLine = styled.div`
  font-size: 18px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 1.4px;
  margin-bottom: 36px;
  color: ${({ lightText }) => (lightText ? '#5ce3d9' : '#5ce3d9')};
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
  margin-bottom: 16px;
`;

export const ImgSalehours = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 50px;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 36px;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
`;

export const HeadingOne = styled.h1`
  margin-bottom: 36px;
  margin-top: 36px;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
`;

export const Span = styled.span`
  margin-bottom: 36px;
  margin-top: 36px;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#5ce1e6' : '#5ce1e6')};
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 30px;
`;

export const ToSalehours = styled.a`
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  text-decoration: none;
`;

export const StyledButton = styled.button`
    background-color: #0a2953;
    border-radius: 30px;
    &:hover {
        background-color: #4cc2b7;
    }
    padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
    color: #fff;
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
`;