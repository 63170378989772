import React from 'react'
import {InfoSec, InfoRow, InfoColumn, MiddleColumn,TextWrapper, Subtitle, Divider, SabzlandImg, Img, MainRow } from './Salehours.elements';
import { Container } from '../../globalStyles'
 const BusinessSuite = ({ 
    lightBg,
    lightTextDesc,
    descriptionOne,
    descriptionTwo,
    descriptionThree,
    descriptionFour,
    imgone,
    imgtwo,
    imgthree,
    imgfour,
    imgStart,
    imgsalehours,
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>                   
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <InfoRow imgStart={imgStart}>
                                    <SabzlandImg src={imgone} />
                                </InfoRow>
                                <Subtitle lightTextDesc={lightTextDesc}>{descriptionOne}</Subtitle>
                            </TextWrapper>
                        </InfoColumn>

                        <MiddleColumn><Divider/></MiddleColumn>
                        
                        <InfoColumn>
                            <TextWrapper>
                                <InfoRow imgStart={imgStart}>
                                    <SabzlandImg src={imgtwo} />
                                </InfoRow>
                                <Subtitle lightTextDesc={lightTextDesc}>{descriptionTwo}</Subtitle>
                            </TextWrapper>
                        </InfoColumn>
                        
                        <MiddleColumn><Divider/></MiddleColumn>
                        
                        <InfoColumn>
                            <TextWrapper>
                                <InfoRow imgStart={imgStart}>
                                    <SabzlandImg src={imgthree} />
                                </InfoRow>
                                <Subtitle lightTextDesc={lightTextDesc}>{descriptionThree}</Subtitle>
                            </TextWrapper>
                        </InfoColumn>
                        
                        <MiddleColumn><Divider/></MiddleColumn>
                        
                        <InfoColumn>
                            <TextWrapper>
                                <InfoRow imgStart={imgStart}>
                                    <SabzlandImg src={imgfour} />
                                </InfoRow>
                                <Subtitle lightTextDesc={lightTextDesc}>{descriptionFour}</Subtitle>
                            </TextWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default BusinessSuite;
