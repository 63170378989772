import React from 'react';
import { InfoSec, InfoRow, ToSalehours, TextWrapper, ImgColumn, Heading, StyledButton, ImgWrapper, Img } from './BuildingBusiness.elements';
import { UpperContainer } from '../../globalStyles';
import { PiArrowRightBold } from "react-icons/pi";

const BuildingBusiness = ({
    primary,
    lightBg,
    bgImage,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    descriptionOne,
    descriptionTwo,
    buttonLabel,
    img,
    alt,
    imgStart,
    start,
    icon,
    button,
    linked
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg} bgImage={bgImage}>
            {/* <Navbar /> */}
                <UpperContainer>
                    <InfoRow imgStart={imgStart}>
                        <ImgColumn>
                            <TextWrapper>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <ToSalehours href='https://salehours.com/' target='_blank' rel='noopener noreferrer'>
                                    <StyledButton primary>
                                        Start now &nbsp;
                                        <PiArrowRightBold className="button-icon" size={20} />
                                    </StyledButton>
                                </ToSalehours>
                            </TextWrapper>
                        </ImgColumn>
                        <ImgColumn>
                            <ImgWrapper start={start}>
                                <Img src={img} alt={alt} />
                            </ImgWrapper>
                        </ImgColumn>
                    </InfoRow>
                </UpperContainer>
            </InfoSec>
        </>
    );
}

export default BuildingBusiness;
