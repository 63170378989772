import styled from 'styled-components';

export const InfoSec = styled.div`
  color: #fff;
  background: ${({ lightBg, bgImage }) => 
    lightBg 
      ? `#fff url(${bgImage}) no-repeat center center` 
      : `#09160F url(${bgImage}) no-repeat `};
  background-size: ${({ lightBg }) => (lightBg ? '100px 100px' : '800px 800px')};
  padding-bottom: 60px;
    @media (min-width: 768px) and (max-width: 1200px) {
        background: ${({ lightBg, bgImage }) => 
            lightBg 
            ? `#fff url(${bgImage}) no-repeat center center` 
            : `#09160F url(${bgImage}) no-repeat `};
        background-size: ${({ lightBg }) => (lightBg ? '100px 100px' : '600px 600px')};
    }
    @media (min-width: 608px) and (max-width: 768px) {
      background: ${({ lightBg, bgImage }) => 
            lightBg 
            ? `#fff url(${bgImage}) no-repeat center center` 
            : `#09160F url(${bgImage}) no-repeat center center`};
        background-size: ${({ lightBg }) => (lightBg ? '100px 100px' : '650px 600px')};
    }
    @media (min-width: 425px) and (max-width: 608px) {
      background: ${({ lightBg, bgImage }) => 
            lightBg 
            ? `#fff url(${bgImage}) no-repeat center center` 
            : `#09160F url(${bgImage}) no-repeat center center`};
        background-size: ${({ lightBg }) => (lightBg ? '100px 100px' : '450px 450px')};
    }
    @media (min-width: 320px) and (max-width: 425px) {
      background: ${({ lightBg, bgImage }) => 
            lightBg 
            ? `#fff url(${bgImage}) no-repeat center center` 
            : `#09160F url(${bgImage}) no-repeat center center`};
        background-size: ${({ lightBg }) => (lightBg ? '100px 100px' : '400px 450px')};
    }
`;


export const InfoRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
    
    @media (max-width: 768px) {
        padding-top: 160px;
        flex-direction: column-reverse;
    }
`;

export const StyledButton = styled.button`
    background-color: #0a2953;
    border-radius: 30px;
    &:hover {
        background-color: #4cc2b7;
    }
    padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
    color: #fff;
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
 
`;

export const ToSalehours = styled.a`
    // box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    @media (min-width: 320px) and (max-width: 768px) {
        justify-content: center;
    }       
`;
export const ImgColumn = styled.div`
    margin-bottom: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
    
    @media (max-width: 768px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 45%;
  flex-basis: 50%;
  
  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
    max-width: 400px;
    padding-top: 0;
    padding-bottom: 60px;
    padding-left: 7em;
    @media (min-width: 800px) and (max-width: 1200px) {
        padding-left: 5em;
    }

    @media (min-width: 320px) and (max-width: 800px) {
        padding-left: 0em;
    }    
`;

export const TopLine = styled.div`
    color: ${({ lightTopLine }) => (lightTopLine ? '#a9b3c1' : '#4b59f7')};
    font-size: 18px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 65px;
    line-height: 1.2;
    font-weight: 800;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
      
    @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 48px;
    }
    @media (min-width: 608px) and (max-width: 768px) {
        font-size: 48px;
        text-align: center;
    }
    @media (min-width: 320px) and (max-width: 608px) {
        font-size: 36px;
        text-align: center;
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ lightTextDesc }) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
`;

export const ImgWrapper = styled.div`
   
    display: flex;
    justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;

export const Img = styled.img`
    
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 10px;

    @media (min-width: 320px) and (max-width: 850px) {
        border: 0;
        max-width: 0%;
        display: block;
        margin-bottom: 0px;
    }    
`;
